import { createCasinoOption } from '../../../utils/createOptions';
import Multiselect from '../../../uiKit/inputs/multiselect';
import React from 'react';
import { useSelector } from 'react-redux';
import { partnerConfigSelectors } from '../../../redux/partnersConfig/selectors';
import Delete from '../../../uiKit/buttons/delete';
import { appSelectors } from '../../../redux/app/selectors';

export default function PermissionsByOperatorBlock({
                                                     selectedOperator,
                                                     selectOperator,
                                                     selectedCasino,
                                                     selectCasino,
                                                     deselectCasino,
                                                     deleteBlock
                                                   }) {
  const operators = useSelector(partnerConfigSelectors.getOperators);
  const casino = useSelector(appSelectors.getCasinoListByOperator);
  const casinoOptions = selectedOperator && casino && casino[selectedOperator] ? casino[selectedOperator].map(item => createCasinoOption(item)) : [];

  return (
    <div className={'relative flex flex-col gap-2'}>
      {deleteBlock && <Delete onClick={deleteBlock}
                              className={'absolute top-2 left-[calc(100%+theme(space.1))] opacity-20 hover:!opacity-100 bg-red-500'}>Delete</Delete>}
      <select value={selectedOperator} onChange={selectOperator} className={'!w-full'}>
        <option value={''} disabled hidden />
        {operators && Object.values(operators).map(({ id, name }) =>
          <option key={id} value={id}>{name}</option>
        )}
      </select>
      <Multiselect options={casinoOptions} selected={selectedCasino} select={selectCasino} deselect={deselectCasino}
                   placeholder={'Casino'} className={'[&_input]:!h-6'} contextClassName={'max-h-36 rounded'} />
    </div>
  )
}

import React, {Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import UrlParamsContextProvider from "./contextProviders/urlParamsContext";

import ErrorBoundary from "./components/errorBoundary";
import Preloader from "./components/loading/preloader";
import NotFound from './components/404';
import RestrictedRoute from "./routes/restricted";
import OpenRoute from "./routes/open";

export default React.memo(function App() {

  return (
    <ErrorBoundary>
      <UrlParamsContextProvider>
        <Switch>
          <Route exact path={['/', '/partners-config', '/operators-configs']}
                 render={() => <Suspense fallback={<Preloader/>}><RestrictedRoute/></Suspense>}/>
          <Route exact path={['/player-results', '/player-results/details/:round_id']}
                 render={({history}) => <Suspense fallback={<Preloader/>}><OpenRoute history={history}/></Suspense>}/>
          <Route exact path={'/404'} render={() => <NotFound/>}/>
          <Route exact path={'*'} render={() => <Redirect to={'/404'}/>}/>
        </Switch>
      </UrlParamsContextProvider>
    </ErrorBoundary>
  );
});

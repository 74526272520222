import React, { useCallback, useEffect, useRef, useState } from 'react';
import PartnersConfigSidebar from './sections/sidebar';
import PartnersConfigEditor from './sections/configEditor';
import { useDispatch, useSelector } from 'react-redux';
import { managerRest } from '../../index';
import { partnerConfigSelectors } from '../../redux/partnersConfig/selectors';
import { modalActions } from '../../redux/modals/reducer';
import { modalTypes } from '../../redux/modals/modalTypes';

export default React.memo(function PartnersConfig() {
  const dispatch = useDispatch();
  const config = useSelector(partnerConfigSelectors.getConfig);
  const operators = useSelector(partnerConfigSelectors.getOperators);
  const [selectedOperatorId, setSelectedOperatorId] = useState('');
  const [selectedCasino, setSelectedCasino] = useState('');
  const [saveFunctionPool, setSaveFunctionPool] = useState({});
  const [casinoConfig, setCasinoConfig] = useState({});
  const [overriddenBlocks, setOverriddenBlocks] = useState({});
  const editorRef = useRef(null);

  const setOverridden = useCallback((config) => {
    const overridden = {};
    for (const configKey in config) {
      overridden[configKey] = config[configKey] !== undefined;
    }
    setOverriddenBlocks(overridden)
  }, [setOverriddenBlocks]);

  useEffect(() => {
    managerRest.getOperators();
    managerRest.getRates();
    managerRest.getGameList(['onlyplay']);
  }, []);

  useEffect(() => {
    if (!operators || selectedOperatorId) return;
    setSelectedOperatorId(Object.keys(operators)[0]);
  }, [operators, selectedOperatorId, setSelectedOperatorId]);

  useEffect(() => {
    if (!selectedOperatorId) return;
    managerRest.getPartnerConfig(selectedOperatorId);
  }, [selectedOperatorId]);

  useEffect(() => {
    if (!selectedOperatorId) return;
    const casinoConfig = (selectedCasino && config && config.casinoConfig && config.casinoConfig[selectedCasino]) || {};
    setCasinoConfig(casinoConfig);
    setOverridden(casinoConfig);
  }, [selectedOperatorId, selectedCasino, config, setOverridden, setCasinoConfig]);

  const save = () => {
    let data = {};
    Object.values(saveFunctionPool).forEach(getData => data = { ...data, ...getData() });

    if (selectedCasino) {
      const casinoConfigTemp = (config && config.hasOwnProperty('casinoConfig') && { ...config.casinoConfig }) || {};
      casinoConfigTemp[selectedCasino] = data;
      const casinoConfig = {};
      for (const casinoName in casinoConfigTemp) {
        if (Object.keys(casinoConfigTemp[casinoName]).length) casinoConfig[casinoName] = casinoConfigTemp[casinoName];
      }
      data = {};
      for (const configKey in config) {
        if (configKey === 'casinoConfig') continue;
        data[configKey] = config[configKey];
      }
      if (Object.keys(casinoConfig).length) data.casinoConfig = casinoConfig;
    } else {
      if (config && config.hasOwnProperty('casinoConfig')) data.casinoConfig = {...config.casinoConfig};
    }

    console.log(`configData: partner config \n partner:${operators[selectedOperatorId]?.name}`, data);
    console.log(JSON.stringify(data));

    dispatch(modalActions.showModal(modalTypes.COMPARE_JSON, {
      prevJson: config,
      newJson: data,
      callback: () => managerRest.setPartnerConfig(selectedOperatorId, data),
    }))
  };

  const reset = () => {
    setOverridden(casinoConfig);
    editorRef.current && editorRef.current.reset();
  };

  const getConfigOrCasinoConfigProperty = useCallback((propertyName) => {
    return casinoConfig[propertyName] !== undefined ? casinoConfig[propertyName] : config && config[propertyName];
  }, [casinoConfig, config]);

  const getConfigOrCasinoConfig = useCallback((propertyNames = []) => {
    const existProperties = propertyNames.filter((propertyName) => casinoConfig[propertyName] !== undefined);
    return existProperties.length ? casinoConfig : config;
  }, [casinoConfig, config]);

  return (
    <div className={'page'}>
      <span className={'page_title'}>Operator config</span>
      <div className={'partners_config_controls'}>
      <button onClick={save} className={'button button_blue'}>
        Save
      </button>
      <button onClick={reset} className={'button button_blue'}>
        Reset
      </button>
      </div>

      <div className={'partners_config'}>

        <PartnersConfigSidebar operators={operators} setSelectedOperator={setSelectedOperatorId}
                               selectedOperator={selectedOperatorId} setSelectedCasino={setSelectedCasino}
                               selectedCasino={selectedCasino} />

        <PartnersConfigEditor ref={editorRef}
                              setSaveFunctionPool={setSaveFunctionPool}
                              config={config}
                              casinoConfig={casinoConfig}
                              selectedCasino={selectedCasino}
                              overriddenBlocks={overriddenBlocks}
                              setOverriddenBlocks={setOverriddenBlocks}
                              getConfigOrCasinoConfigProperty={getConfigOrCasinoConfigProperty}
                              getConfigOrCasinoConfig={getConfigOrCasinoConfig} />

      </div>
    </div>
  );
})

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import Info from '../../../inputForms/info';
import AddCurrencyWindow from './addCurrencyWindow';
import GenerateToEurWindow from './generateToEurWindow';
import {useDispatch, useSelector} from 'react-redux';
import {partnerConfigSelectors} from '../../../../redux/partnersConfig/selectors';
import eOperatorsConfigsFieldName from '../../eOperatorsConfigsFieldName';
import AddCheckboxLayout from '../../../inputForms/addCheckboxLayout';
import DropdownLayout from '../../../dropdownLayout';
import { roundBets } from '../../../../utils/betsGenerator';
import {modalActions} from '../../../../redux/modals/reducer';
import {modalTypes} from '../../../../redux/modals/modalTypes';
import {integerInputRegExp} from '../../../../utils/regexp/numbers';

import {ReactComponent as DeleteIcon} from '../../../../assets/icons/uncheck.svg';
import EditWindow from './editWindow';

export default React.memo(forwardRef(function AvailableBets({
                                                              configForView,
                                                              canChangeBlock,
                                                              canOverride,
                                                              setSaveFunctionPool,
                                                              overriddenBlocks,
                                                              toggleOverriddenField,
                                                              setValueToConfigForView
                                                            }, ref) {
  const dispatch = useDispatch();
  const [showAddCurrencyWindow, setShowAddCurrencyWindow] = useState(false);
  const [showGenerateToEurWindow, setShowGenerateToEurWindow] = useState(false);
  const [showEditWindow, setShowEditWindow] = useState(false);
  const [defaultBetIndexMode, setDefaultBetIndexMode] = useState(false);
  const defaultFormData = useMemo(() => ({
    currencies: '',
    currenciesArr: [],
    currenciesViewDecimals: {},
    currenciesDefaultBetIndexes: {},
  }), []);
  const rates = useSelector(partnerConfigSelectors.getRates);
  const [availableBets, setAvailableBets] = useState({});
  const [formData, setFormData] = useState(defaultFormData);

  const getPreparedCurrencyForConfig = useCallback((currenciesDecimals = formData.currenciesViewDecimals, currenciesDefaultBetIndexes = formData.currenciesDefaultBetIndexes) => {
    const currenciesDecimalsKeys = Object.keys({...currenciesDecimals, ...currenciesDefaultBetIndexes});
    return currenciesDecimalsKeys.reduce((prev, key) => {
      const currencySettings = {};
      if (typeof currenciesDecimals[key] === 'number') currencySettings.viewDecimals = currenciesDecimals[key];
      if (typeof currenciesDefaultBetIndexes[key] === 'number') currencySettings.defaultBetIndex = currenciesDefaultBetIndexes[key];
      prev[key.toLowerCase()] = currencySettings;
      return prev;
    }, {})
  }, [formData.currenciesViewDecimals, formData.currenciesDefaultBetIndexes]);

  const getPreparedAvailableBetsForConfig = useCallback((newAvailableBets) => {
    const availableBetsKey = Object.keys(newAvailableBets);
    return availableBetsKey.reduce((prev, currency) => {
      prev[currency.toLowerCase()] = newAvailableBets[currency];
      return prev;
    }, {});
  }, []);

  const saveFunction = useCallback(() => {
    const data = {};
    if (configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS] === configForView.type) {
      data[eOperatorsConfigsFieldName.EPCFN_CURRENCIES] = getPreparedCurrencyForConfig();
      data[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS] = getPreparedAvailableBetsForConfig(availableBets)
    }
    return data;
  }, [availableBets, overriddenBlocks, getPreparedCurrencyForConfig, configForView.isGlobalConfig, configForView.type, getPreparedAvailableBetsForConfig]);

  const getViewDecimals = useCallback((currencies) => {
    const config = configForView.config;
    const currenciesDecimalsConfig = config?.currencies;
    const result = {};

    currencies.forEach(key => {
      let configKey;

      if (currenciesDecimalsConfig) {
        if (currenciesDecimalsConfig.hasOwnProperty(key)) {
          configKey = key;
        } else if (currenciesDecimalsConfig.hasOwnProperty(key.toLowerCase())) {
          configKey = key.toLowerCase();
        }
      }

      if (configKey && typeof currenciesDecimalsConfig[configKey].viewDecimals === 'number') {
        result[key.toUpperCase()] = currenciesDecimalsConfig[configKey].viewDecimals;
      }
    });
    return result
  }, [configForView.config]);

  const getDefaultBetIndexes = useCallback((currencies) => {
    const config = configForView.config;
    const currenciesDefaultBetIndexesConfig = config?.currencies;
    const result = {};

    currencies.forEach(key => {
      let configKey;

      if (currenciesDefaultBetIndexesConfig) {
        if (currenciesDefaultBetIndexesConfig.hasOwnProperty(key)) {
          configKey = key;
        } else if (currenciesDefaultBetIndexesConfig.hasOwnProperty(key.toLowerCase())) {
          configKey = key.toLowerCase();
        }
      }

      if (configKey && typeof currenciesDefaultBetIndexesConfig[configKey].defaultBetIndex === 'number') {
        result[key.toUpperCase()] = currenciesDefaultBetIndexesConfig[configKey].defaultBetIndex;
      }
    });

    return result
  }, [configForView.config]);

  const generateAvailableBetsToEur = useCallback((currencies, EURBets = availableBets['EUR']) => {
    if (!EURBets) {
      dispatch(modalActions.showModal(modalTypes.ERROR, 'EUR available bets must be specified'));
      return;
    }

    const result = {};
    if (!rates) return result;

    const unexistRatesForCurrencies = [];
    for (let currency of currencies) {
      let rate = rates[currency.toUpperCase()];
      if (currency === 'dog') {
        rate = rates['DOGE'];
      }
      if (!rate) {
        console.warn('Can`t find rate for currency:', currency);
        unexistRatesForCurrencies.push(currency);
        delete result[currency];
        continue;
      }
      const betsArray = EURBets.map(bet => bet * rate);
      if (betsArray.length > 0) result[currency.toUpperCase()] = roundBets(betsArray, currency);
    }

    if (unexistRatesForCurrencies.length > 0) dispatch(modalActions.showModal(modalTypes.ERROR, `Can\`t find rate for currency: ${unexistRatesForCurrencies.join(', ')}`));
    return result;
  }, [dispatch, rates, availableBets]);

  const setAvailableBetsData = useCallback((config) => {
    const newAvailableBets = config.available_bets
      ? Object.keys(config.available_bets).reduce((prev, currency) => {
        prev[currency.toUpperCase()] = config.available_bets[currency];
        return prev
      }, {})
      : {};

    setAvailableBets(newAvailableBets);

    const currenciesArr = (config.available_bets && Object.keys(config.available_bets).map(currency => currency.toUpperCase())) || defaultFormData.currenciesArr;
    const currenciesDecimals = getViewDecimals(currenciesArr);
    const currenciesDefaultBetIndexes = getDefaultBetIndexes(currenciesArr);

    setFormData({
      ...defaultFormData,
      currenciesArr,
      currenciesViewDecimals: currenciesDecimals,
      currenciesDefaultBetIndexes: currenciesDefaultBetIndexes,
      currencies: (config.available_bets && currenciesArr.join(', ')) || defaultFormData.currencies,
    })
  }, [setAvailableBets, setFormData, defaultFormData, getViewDecimals, getDefaultBetIndexes]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [configForView.type]: {
        ...prev[configForView.type],
        [eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS]: saveFunction
      }
    }));
  }, [setSaveFunctionPool, saveFunction, configForView.type]);

  useEffect(() => {
    if (!rates) return;
    setAvailableBetsData(configForView.config);
  }, [configForView.config, setAvailableBetsData, rates]);

  //check DefaultBetIndexes with max betsCount
  // useEffect(() => {
  //   const currencies = Object.keys(formData.currenciesDefaultBetIndexes);
  //   const newCurrencies = currencies.filter(currency => formData.currenciesDefaultBetIndexes[currency] < formData.betsCount);
  //   if (currencies.length !== newCurrencies.length) {
  //     const newCurrenciesDefaultBetIndexes = newCurrencies.reduce((prev, currency) => {
  //       prev[currency] = formData.currenciesDefaultBetIndexes[currency];
  //       return prev;
  //     }, {});
  //     setFormData(prev => ({
  //       ...prev,
  //       currenciesDefaultBetIndexes: newCurrenciesDefaultBetIndexes
  //     }))
  //   }
  // }, [formData.currenciesDefaultBetIndexes, formData.betsCount])

  const changeDecimals = useCallback((e) => {
    if (!integerInputRegExp.test(e.target.value)) return;

    setFormData(prev => ({
      ...prev,
      currenciesViewDecimals: {
        ...prev.currenciesViewDecimals,
        [e.target.dataset.currency.toUpperCase()]: e.target.value
      }
    }));
  }, [setFormData]);

  const onBlurDecimals = useCallback((e) => {
    const currenciesViewDecimals = {
      ...formData.currenciesViewDecimals,
      [e.target.dataset.currency.toUpperCase()]: Number(formData.currenciesViewDecimals[e.target.dataset.currency.toUpperCase()])
    }

    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CURRENCIES, getPreparedCurrencyForConfig(currenciesViewDecimals));
  }, [setValueToConfigForView, formData.currenciesViewDecimals, configForView.type, getPreparedCurrencyForConfig]);

  const onClickBetInput = useCallback((currency, betIndex) => {
    if (!defaultBetIndexMode) return;
      let currenciesDefaultBetIndexes = {
        ...formData.currenciesDefaultBetIndexes,
      };
      currenciesDefaultBetIndexes = Object.fromEntries(Object.entries(currenciesDefaultBetIndexes).filter(arr => arr[0] !== currency.toUpperCase() || arr[1] !== betIndex));
      if (formData.currenciesDefaultBetIndexes[currency.toUpperCase()] !== betIndex) {
        currenciesDefaultBetIndexes[currency.toUpperCase()] = betIndex;
      }
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CURRENCIES, getPreparedCurrencyForConfig(undefined, currenciesDefaultBetIndexes));
  }, [formData, setValueToConfigForView, configForView.type, getPreparedCurrencyForConfig, defaultBetIndexMode]);

  const onChangeAvailableBet = (availableBet, currency, betIndex) => {
    setAvailableBets(prev => {
      const availableBetsByCurrency = Array.isArray(prev[currency]) ? [...prev[currency]] : [];
      availableBetsByCurrency[betIndex] = availableBet;
      return {
        ...prev,
        [currency]: availableBetsByCurrency
      }
    });
  };

  const onBlurAvailableBet = useCallback((bet, currency, betIndex) => {
    let availableBet = bet;
    if (!(bet > 0)) {
      availableBet = availableBets[currency][betIndex];
    }

    const availableBetsByCurrency = Array.isArray(availableBets[currency]) ? [...availableBets[currency]] : [];
    availableBetsByCurrency[betIndex] = +availableBet;
    const newAvailableBets = {
      ...availableBets,
      [currency]: availableBetsByCurrency
    }

    const preparedAvailableBetsForConfig = getPreparedAvailableBetsForConfig(newAvailableBets);

    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS, preparedAvailableBetsForConfig);
  },[availableBets, setValueToConfigForView, configForView.type, getPreparedAvailableBetsForConfig]);

  const checkOverride = useCallback(() => {
    toggleOverriddenField(configForView.type, [eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS, eOperatorsConfigsFieldName.EPCFN_CURRENCIES]);
  }, [toggleOverriddenField, configForView]);

  const checkOverrideDecimalsView = useCallback((currency) => (isOverridden) => {
      const decimalsView = {...formData.currenciesViewDecimals};
      if (isOverridden) {
        const config = configForView.config;
        const currenciesDecimalsConfig = config.currencies;
        decimalsView[currency.toUpperCase()] = currenciesDecimalsConfig?.[currency.toLowerCase()]?.hasOwnProperty('viewDecimals') ?
          currenciesDecimalsConfig[currency.toLowerCase()].viewDecimals : 0;
      } else {
        delete decimalsView[currency.toUpperCase()];
      }
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CURRENCIES, getPreparedCurrencyForConfig(decimalsView));
  },[configForView.config, setValueToConfigForView, configForView.type, formData.currenciesViewDecimals, getPreparedCurrencyForConfig]);

  useLayoutEffect(() => {
    if (!rates) return;
    setFormData(prev => {
      const currenciesArr = prev.currenciesArr === '' || prev.currenciesArr.length === 0 ? defaultFormData.currenciesArr : prev.currenciesArr;
      return {
        ...prev,
        currenciesArr
      }
    });

  }, [rates, setFormData, defaultFormData]);

  const reset = useCallback(() => {
    setAvailableBetsData(configForView.config);
  },[setAvailableBetsData, configForView.config]);

  const onGenerateClick = () => {
    setShowEditWindow(true);

  };

  const openAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(true);
  };

  const toggleDefaultBetIndexMode = () => {
    setDefaultBetIndexMode(prev => !prev);
  };

  const openGenerateToEurWindow = () => {
    setShowGenerateToEurWindow(true);
  };

  const closeAddCurrencyWindow = () => {
    setShowAddCurrencyWindow(false);
  };

  const closeGenerateToEurWindow = () => {
    setShowGenerateToEurWindow(false);
  };
  const closeEditWindow = () => {
    setShowEditWindow(false);
  };

  const addCurrenciesAndBets = useCallback((currencies, bets) => {
    setAvailableBets(prev => ({...prev, ...bets}));
    const preparedAvailableBetsForConfig = getPreparedAvailableBetsForConfig({...availableBets, ...bets});
    const currenciesArr = Array.from(new Set([...formData.currenciesArr, ...currencies]));
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS, preparedAvailableBetsForConfig);
    const viewDecimals = getViewDecimals(currenciesArr);
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CURRENCIES, getPreparedCurrencyForConfig(viewDecimals));
  }, [formData.currenciesArr, getViewDecimals, setValueToConfigForView, configForView.type, availableBets, getPreparedAvailableBetsForConfig, getPreparedCurrencyForConfig]);




  const tryToGenerate = useCallback((currencies, EURBets = availableBets['EUR']) => {
    const alreadyExistList = [];
    const newList = [];

    for (let currency of currencies) {
      if (availableBets.hasOwnProperty(currency.toUpperCase())) {
        alreadyExistList.push(currency);
      } else {
        newList.push(currency);
      }
    }

    if (alreadyExistList.length) {
      const bets = generateAvailableBetsToEur(alreadyExistList, EURBets);
      for (let currency in bets) {
        bets[currency] = roundBets(bets[currency]);
      }
      if (EURBets) bets['EUR'] = EURBets;
      addCurrenciesAndBets(alreadyExistList, bets);
    }

    if (newList.length) {
      dispatch(modalActions.showModal(modalTypes.ERROR, `Currencies not found: ${newList.join(', ')}`));
    }
  }, [addCurrenciesAndBets, availableBets, dispatch, generateAvailableBetsToEur]);

  const tryToAddCurrencies = useCallback((currencies) => {
    const alreadyExistList = [];
    const newList = [];

    for (let currency of currencies) {
      if (availableBets.hasOwnProperty(currency)) {
        alreadyExistList.push(currency);
      } else {
        newList.push(currency);
      }
    }
    let newBets = {};
    if (newList.length) {
      const emptyBetsList = newList.reduce((prev, currency) => {
        prev[currency] = [];
        return prev;
      }, {})
      newBets = availableBets['EUR'] ? generateAvailableBetsToEur(newList, availableBets['EUR']) : emptyBetsList;
    }

    if (alreadyExistList.length) {
      dispatch(modalActions.showModal(modalTypes.CONFIRM, {
        text: `${alreadyExistList.join(', ')} already exist in available bets list, override?`,
        buttons: [
          {label: 'Cancel', callback: () => {
              if (newList.length) addCurrenciesAndBets(newList, newBets);
            }
            },
          {
            label: 'Yes',
            callback: async () => {
              const emptyBetsList = alreadyExistList.reduce((prev, currency) => {
                prev[currency] = [];
                return prev;
              }, {})
              const updatedBets = availableBets['EUR'] ? generateAvailableBetsToEur(alreadyExistList, availableBets['EUR']) : emptyBetsList;
              addCurrenciesAndBets([ ...newList, ...alreadyExistList ], { ...newBets, ...updatedBets })
            }
          }
        ]
      }));
    } else if (newList.length) {
      addCurrenciesAndBets(newList, newBets);
    }
  }, [addCurrenciesAndBets, availableBets, dispatch, generateAvailableBetsToEur]);

  const deleteCurrencyAndBets = useCallback((currency) => {
    const bets = Object.keys(availableBets).reduce((res, key) => {
      if (key !== currency) {
        res[key] = availableBets[key];
      }
      return res;
    }, {});

    const preparedAvailableBetsForConfig = getPreparedAvailableBetsForConfig(bets);
    const currenciesArr = formData.currenciesArr.filter(curr => curr !== currency);
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS, preparedAvailableBetsForConfig);
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CURRENCIES, getPreparedCurrencyForConfig(getViewDecimals(currenciesArr)));
  }, [availableBets, getPreparedAvailableBetsForConfig, formData.currenciesArr, setValueToConfigForView, configForView.type, getPreparedCurrencyForConfig, getViewDecimals]);

  const updateCurrencyAndBets = useCallback((bets) => {
    const preparedAvailableBetsForConfig = getPreparedAvailableBetsForConfig(bets);
    const currenciesArr = Object.keys(bets);
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS, preparedAvailableBetsForConfig);
    setValueToConfigForView(configForView.type, eOperatorsConfigsFieldName.EPCFN_CURRENCIES, getPreparedCurrencyForConfig(getViewDecimals(currenciesArr)));
  }, [getPreparedAvailableBetsForConfig, setValueToConfigForView, configForView.type, getPreparedCurrencyForConfig, getViewDecimals]);

  const onDeleteAllBets = useCallback(() => {
    dispatch(modalActions.showModal(modalTypes.CONFIRM, {
      text: `Delete all bets`,
      buttons: [
        {label: 'Cancel'},
        {label: 'Yes', callback: () => updateCurrencyAndBets({})}
      ]
    }));
  }, [dispatch, updateCurrencyAndBets]);

  const onEditCallback = useCallback((bets) => {
    updateCurrencyAndBets(bets);
  }, [updateCurrencyAndBets]);

  const showConfirmDeleting = useCallback((e) => {
    const currency = e.currentTarget.dataset.currency;
    dispatch(modalActions.showModal(modalTypes.CONFIRM, {
      text: `Delete
      '${currency}' from available bets?`,
      buttons: [
        {label: 'Cancel'},
        {label: 'Yes', callback: () => deleteCurrencyAndBets(currency)}
      ]
    }));
  }, [dispatch, deleteCurrencyAndBets]);

  const maxBetsCount = useMemo(()=>{
    return Object.values(availableBets).reduce((prev, bets) => bets.length > prev ? bets.length : prev, 0);
  }, [availableBets]);

  const currenciesForView = useMemo(()=> {
    const currenciesForView = formData.currenciesArr ? formData.currenciesArr : [];
    currenciesForView.sort(currency => currency === 'EUR' ? -1 : 0)
    return currenciesForView
  }, [formData]);

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'operators_configs_body_form_box'} data-disabled={!canChangeBlock}>
      {!configForView.isGlobalConfig && canOverride &&
        <div className={'operators_configs_override'}>
          <AddCheckboxLayout title={'override'}
                             checked={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS] === configForView.type}
                             checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
                             additionalInfo={overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS]}
          />
        </div>}
      <span className={'operators_configs_body_form_box_title'}>Available Bets</span>
      <div className={'input_form'}>
        <span className={'input_form_title'}>currencies</span>
        <input className={'big_input'}
               type={'string'}
               value={formData.currencies}
               disabled={!canChangeBlock}
               readOnly={true}
        />
        <Info info={''}/>
      </div>

      <div>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={onGenerateClick}>Edit
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={openGenerateToEurWindow}>Update from EUR
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={openAddCurrencyWindow}>Add currency
        </button>
        <button className={'button button_light_blue'} disabled={!canChangeBlock} onClick={onDeleteAllBets}>Delete all bets</button>

        <button className={'button button_light_blue'} disabled={!canChangeBlock}
                onClick={toggleDefaultBetIndexMode}>{defaultBetIndexMode ? 'Change available bets' : 'Change default bet indexes'}
        </button>

        <AddCurrencyWindow visible={showAddCurrencyWindow} close={closeAddCurrencyWindow}
                           callback={tryToAddCurrencies}/>
        <GenerateToEurWindow visible={showGenerateToEurWindow} close={closeGenerateToEurWindow}
                             callback={tryToGenerate}/>
        {showEditWindow ? <EditWindow close={closeEditWindow}
                     callback={onEditCallback} currenciesArr={formData.currenciesArr} availableBets={availableBets}/> : null}
      </div>

      <DropdownLayout title={'Bets'}>
        <div className="available_bets_bets_block">
          <div>
            <div className={'input_form available_bets_row'}></div>
            {currenciesForView.map(currency =>
                <div key={currency} className={'input_form available_bets_row'}>
            <span className={'available_bets_row_currency'}>
              <button onClick={showConfirmDeleting} disabled={!canChangeBlock} data-currency={currency}>
                <DeleteIcon/>
              </button>
              {currency}
            </span>

                  <AddCheckboxLayout title={'Decimals view:'} childrenPosition={'right'}
                                     checked={formData.currenciesViewDecimals.hasOwnProperty(currency.toUpperCase()) || false}
                                     disabled={!canChangeBlock}
                                     checkboxId={`${configForView.type}_${eOperatorsConfigsFieldName.EPCFN_CURRENCIES}_${currency}_override_checkbox`}
                                     setChecked={checkOverrideDecimalsView(currency)}>
                  <span>
                    <input
                      value={formData.currenciesViewDecimals.hasOwnProperty(currency.toUpperCase()) ? formData.currenciesViewDecimals[currency.toUpperCase()] : 'default'}
                      onChange={changeDecimals} onBlur={onBlurDecimals} data-currency={currency}
                      disabled={!canChangeBlock || !formData.currenciesViewDecimals.hasOwnProperty(currency.toUpperCase())}/>
                  </span>
                  </AddCheckboxLayout>

                  <span>Range:</span>
                  <button className={'button euro_button button_light_blue'} onClick={() => tryToGenerate([currency])} disabled={!canChangeBlock || currency === 'EUR'} data-currency={currency}>
                    &euro;
                  </button>
                </div>
            )}
          </div>
          <div className="available_bets_bets_List">
            <div className={'input_form available_bets_row'}>
              {
                (new Array(maxBetsCount)).fill().map((_, betIndex) => <div className={'bet_index'} key={'bet_index' + betIndex}>{betIndex}</div>)
              }
            </div>
            {currenciesForView.map(currency =>
              <div key={currency} className={'input_form available_bets_row'}>
                {(new Array(maxBetsCount)).fill().map((_, betIndex) => {
                  const value = availableBets[currency]
                  && availableBets[currency][betIndex] !== undefined
                    ? availableBets[currency][betIndex]
                    : ''
                    return (
                      <input key={currency + betIndex} type={'number'}
                             className={`${(formData.currenciesDefaultBetIndexes.hasOwnProperty(currency.toUpperCase()) ? formData.currenciesDefaultBetIndexes[currency.toUpperCase()] : (formData.currenciesDefaultBetIndexes['EUR'] && availableBets[currency]?.[formData.currenciesDefaultBetIndexes['EUR']] ? formData.currenciesDefaultBetIndexes['EUR'] : 0) || 0) === betIndex && value ? (formData.currenciesDefaultBetIndexes.hasOwnProperty(currency.toUpperCase()) ? 'blue_border' : 'blue_light_border') : ''} ${defaultBetIndexMode && value ? 'cursor_pointer' : ''}`}
                             readOnly={defaultBetIndexMode}
                             value={value}
                             onChange={(e) => onChangeAvailableBet(e.target.value, currency, betIndex)}
                             onBlur={(e) => onBlurAvailableBet(e.target.value, currency, betIndex)}
                             disabled={!canChangeBlock || (defaultBetIndexMode && !value)}
                             onClick={() => onClickBetInput(currency, betIndex)}
                             onWheel={(e) => e.deltaY !== 0 && e.target.blur()}
                      />
                    )
                  }
                )}
              </div>
            )}
          </div>
          <div>
            <div className={'input_form available_bets_row'}></div>
            {currenciesForView.map(currency =>
              <div key={currency} className={'input_form available_bets_row'}>
                <Info info={''}/>
              </div>
            )}
          </div>
        </div>

      </DropdownLayout>

    </div>
  );
}));

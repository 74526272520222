import React, {forwardRef, useImperativeHandle, useMemo, useRef} from 'react';
import SingleBooleanField from './singleBooleanField';
import Session from './session';
import RoundDuration from './roundDuration';
import CustomResourcePool from './customResourcePool';
import SingleStringField from './singleStringField';
import CertificationName from './certificationName';
import WinLimits from './winLimit';
import FreeBets from './freebets/freebets';
import Rtp from './rtp';
import AvailableBets from './availableBets/availableBets';
import Jackpot from './jackpot';
import MessageEvents from './messageEvents/messageEvents';
import eOperatorsConfigsFieldName from '../eOperatorsConfigsFieldName';
import {useSelector} from "react-redux";
import {userSelectors} from "../../../redux/user/selectors";
import eUserRoles from "../../../enums/eUserRoles";

export default React.memo(forwardRef(function OperatorConfigEditor({
                                                             configForView,
                                                             setSaveFunctionPool,
                                                             overriddenBlocks,
                                                             toggleOverriddenField,
                                                             className,
                                                             setValueToConfigForView
                                                           }, ref) {
  const user = useSelector(userSelectors.getUser);
  const canEditFields = useMemo(() => {
    if ([eUserRoles.EUR_ADMIN, eUserRoles.EUR_INTEGRATION_MANAGER].includes(user.role)) {
      return new Set(Object.values(eOperatorsConfigsFieldName));
    } else {
      return new Set(user.partnerConfigAllowToEditFields || []);
    }
  }, [user]);
  const singleBooleanFields = useMemo(() => ({
    [eOperatorsConfigsFieldName.EPCFN_IFRAME_HOME_BUTTON]: 'Frame home button',
    [eOperatorsConfigsFieldName.EPCFN_HOME_BUTTON_HISTORY_BACK_DISABLE]: 'Home Button History Back Disable',
    [eOperatorsConfigsFieldName.EPCFN_CANCEL_ENABLED]: 'Cancel enabled',
    [eOperatorsConfigsFieldName.EPCFN_IS_MAX_BETS_COUNT_ONE]: 'Is max bets count one',
    [eOperatorsConfigsFieldName.EPCFN_SHOW_OPERATOR_BETS_IDS]: 'Show operator bets ids',
    [eOperatorsConfigsFieldName.EPCFN_DISABLE_BUY_BONUS]: 'Disable buy bonus',
    [eOperatorsConfigsFieldName.EPCFN_SHOW_RULES_ON_START]: 'Show rules on start',
    [eOperatorsConfigsFieldName.EPCFN_SHOW_GAME_STATE]: 'Show game state',
    [eOperatorsConfigsFieldName.EPCFN_DISABLE_AUTOPLAY]: 'Disable autoplay',
    [eOperatorsConfigsFieldName.EPCFN_DISABLE_SPIN_BY_SPACE]: 'Disable spin by space',
    [eOperatorsConfigsFieldName.EPCFN_SHOW_STOPWATCH]: 'Show stopwatch',
    [eOperatorsConfigsFieldName.EPCFN_SHOW_ClOCK]: 'Show clock',
    [eOperatorsConfigsFieldName.EPCFN_SHOW_NET_POSITION]: 'Show net position',
    [eOperatorsConfigsFieldName.EPCFN_ENABLE_REALITY_CHECK]: 'Enable reality check',
    [eOperatorsConfigsFieldName.EPCFN_SKIP_BLOCKED]: 'Skip blocked',
    [eOperatorsConfigsFieldName.EPCFN_LIGHT_MODE]: 'Light mode',
    [eOperatorsConfigsFieldName.EPCFN_BET_INDEX_CUSTOM_RANGE_SETUP]: 'Bet index custom range setup',
    [eOperatorsConfigsFieldName.EPCFN_ENABLE_SOCIAL_LANGUAGE]: 'Enable social language',
    [eOperatorsConfigsFieldName.EPCFN_USE_LOW_QUALITY_ASSETS]: 'Use Low Quality Assets',
  }), []);

  const singleStringFields = useMemo(() => ({

  }), []);

  const fields = useRef(Object.fromEntries(Object.values(eOperatorsConfigsFieldName).map(fieldName => [fieldName, React.createRef()])));

  const reset = () => {
    Object.values(fields.current).forEach(ref => {
      if (!ref.current) return;
      ref.current.reset()
    })
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={`operators_configs_body ${className}`}>
      <div className={'operators_configs_grid_in_3_colum'}>
        {Object.keys(singleBooleanFields).map(key =>
          <SingleBooleanField ref={fields.current[key]} key={key} fieldName={key} title={singleBooleanFields[key]}
                              initialValue={false}
                              canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[key] === configForView.type) && canEditFields.has(key)}
                              canOverride={canEditFields.has(key)}
                              configForView={configForView} setSaveFunctionPool={setSaveFunctionPool}
                              overriddenBlocks={overriddenBlocks}
                              toggleOverriddenField={toggleOverriddenField}
                              setValueToConfigForView={setValueToConfigForView}
                              info={''}/>
        )}
      </div>
      <div className={'operators_configs_grid_in_3_colum'}>
        {Object.keys(singleStringFields).map(key =>
          <SingleStringField key={key} ref={fields.current[key]} fieldName={key} title={singleStringFields[key]} configForView={configForView}
                             setSaveFunctionPool={setSaveFunctionPool}
                             canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[key] === configForView.type) && canEditFields.has(key)}
                             canOverride={canEditFields.has(key)}
                             overriddenBlocks={overriddenBlocks}
                             toggleOverriddenField={toggleOverriddenField}
                             setValueToConfigForView={setValueToConfigForView}
          />
        )}
      </div>

      <CertificationName ref={fields.current[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME]} configForView={configForView}
                         setSaveFunctionPool={setSaveFunctionPool}
                         canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME)}
                         canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_CERTIFICATION_NAME)}
                         overriddenBlocks={overriddenBlocks}
                         toggleOverriddenField={toggleOverriddenField}
                         setValueToConfigForView={setValueToConfigForView}
      />

      <RoundDuration ref={fields.current[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION]} configForView={configForView}
                     setSaveFunctionPool={setSaveFunctionPool}
                     canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION)}
                     canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_ROUND_DURATION)}
                     overriddenBlocks={overriddenBlocks}
                     toggleOverriddenField={toggleOverriddenField}
                     setValueToConfigForView={setValueToConfigForView}
      />
      <div className={'operators_configs_grid_in_2_colum'}>
        <Rtp ref={fields.current[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP]} configForView={configForView}
             canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP)}
             canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_CUSTOM_RTP)}
             setSaveFunctionPool={setSaveFunctionPool}
             overriddenBlocks={overriddenBlocks}
             toggleOverriddenField={toggleOverriddenField}
             setValueToConfigForView={setValueToConfigForView}
        />

        <SingleStringField ref={fields.current[eOperatorsConfigsFieldName.EPCFN_DISPLAY_RTP]} fieldName={eOperatorsConfigsFieldName.EPCFN_DISPLAY_RTP} title={'Display RTP'} configForView={configForView}
                           setSaveFunctionPool={setSaveFunctionPool}
                           canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_DISPLAY_RTP] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_DISPLAY_RTP)}
                           canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_DISPLAY_RTP)}
                           overriddenBlocks={overriddenBlocks}
                           toggleOverriddenField={toggleOverriddenField}
                           setValueToConfigForView={setValueToConfigForView}
        />
      </div>
      <CustomResourcePool ref={fields.current[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL]} configForView={configForView}
               setSaveFunctionPool={setSaveFunctionPool}
               canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL)}
               canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_CUSTOM_RESOURCE_POOL)}
               overriddenBlocks={overriddenBlocks}
               toggleOverriddenField={toggleOverriddenField}
               setValueToConfigForView={setValueToConfigForView}
      />
      <Session ref={fields.current[eOperatorsConfigsFieldName.EPCFN_SESSION]} configForView={configForView}
               setSaveFunctionPool={setSaveFunctionPool}
               canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_SESSION] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_SESSION)}
               canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_SESSION)}
               overriddenBlocks={overriddenBlocks}
               toggleOverriddenField={toggleOverriddenField}
               setValueToConfigForView={setValueToConfigForView}
      />

      <WinLimits ref={fields.current[eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT]} configForView={configForView}
                 canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT] === configForView.type|| overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START] === configForView.type) && (canEditFields.has(eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT) || canEditFields.has(eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START))}
                 canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_MAX_WIN_LIMIT) || canEditFields.has(eOperatorsConfigsFieldName.EPCFN_SHOW_MAX_WIN_ON_START)}
                 setSaveFunctionPool={setSaveFunctionPool}
                 overriddenBlocks={overriddenBlocks}
                 toggleOverriddenField={toggleOverriddenField}
                 setValueToConfigForView={setValueToConfigForView}
      />

      <FreeBets ref={fields.current[eOperatorsConfigsFieldName.EPCFN_FREE_BETS]} configForView={configForView}
                canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_FREE_BETS] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_FREE_BETS)}
                canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_FREE_BETS)}
                setSaveFunctionPool={setSaveFunctionPool}
                overriddenBlocks={overriddenBlocks}
                toggleOverriddenField={toggleOverriddenField}
                setValueToConfigForView={setValueToConfigForView}
      />
      <Jackpot ref={fields.current[eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED]} configForView={configForView}
               canChangeBlock={(configForView.isGlobalConfig || (overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED] === configForView.type && overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_JACKPOT_PAYOUT_THRESHOLD] === configForView.type)) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED)}
               canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_JACKPOT_ENABLED)}
               setSaveFunctionPool={setSaveFunctionPool}
               overriddenBlocks={overriddenBlocks}
               toggleOverriddenField={toggleOverriddenField}
               setValueToConfigForView={setValueToConfigForView}
      />

      <AvailableBets ref={fields.current[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS]} configForView={configForView}
                     canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS)}
                     canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_AVAILABLE_BETS)}
                     setSaveFunctionPool={setSaveFunctionPool}
                     overriddenBlocks={overriddenBlocks}
                     toggleOverriddenField={toggleOverriddenField}
                     setValueToConfigForView={setValueToConfigForView}

      />

      <MessageEvents ref={fields.current[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS]} configForView={configForView}
                     canChangeBlock={(configForView.isGlobalConfig || overriddenBlocks[eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS] === configForView.type) && canEditFields.has(eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS)}
                     canOverride={canEditFields.has(eOperatorsConfigsFieldName.EPCFN_MESSAGE_EVENTS)}
                     setSaveFunctionPool={setSaveFunctionPool}
                     overriddenBlocks={overriddenBlocks}
                     toggleOverriddenField={toggleOverriddenField}
                     setValueToConfigForView={setValueToConfigForView}
      />

    </div>
  );
}));

import { createId } from '../../utils/transactionHelpers';

export const appSelectors = {
  getRounds(state) {
    return state.app.rounds;
  },

  getLoading(state) {
    return state.app.loading;
  },

  getOptions(state) {
    return state.app.options;
  },

  getUserRoles(state) {
    return state.app.roles;
  },

  getCasinoList(state) {
    return state.app.casino_list;
  },

  getCasinoListByOperator(state) {
    return state.app.casino_list_by_operator;
  },

  getTxs(id, region) {
    return (state) => {
      return state.app.txs[createId(id, region)];
    }
  }
};

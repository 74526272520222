import { useEffect, useRef, useState } from 'react';
import { managerRest } from '../../index';
import { useOpenRouteContext } from '../../contextProviders/openRouteContext';
import { openRouteSelectors } from '../../redux/openRoute/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { openRouteActions } from '../../redux/openRoute/reducer';

const useDetailsModel = (roundId) => {
  const { sessionParams, urlParams, isFetching } = useOpenRouteContext();
  const { hash } = urlParams;
  const frameRef = useRef();
  const [frameReady, setFrameReady] = useState(false);
  const [frameLoaded, setFrameLoaded] = useState(false);
  const details = useSelector(openRouteSelectors.getDetails(roundId));
  const [loading, setLoading] = useState(!details);
  const dispatch = useDispatch();

  useEffect(() => {
    if (details) return;

    let mounted = true;
    const abortController = new AbortController();
    const signal = abortController.signal;

    const getDetails = async () => {
      setLoading(true);
      const details = await managerRest.getRoundDetails({ hash, id: parseInt(roundId) }, { signal });
      if (!mounted) return;

      if (details) dispatch(openRouteActions.setDetails(details));
      setLoading(false);
    };

    getDetails();

    return () => {
      mounted = false;
      abortController.abort();
    }
  }, [roundId, hash, details, dispatch]);

  useEffect(() => {
    if (!frameReady || !details) return;

    const params = {
      round_params: {
        round_info: details.round,
        results_info: details.results,
      },
      version: details.results?.version,
    }
    try {
      const message = JSON.stringify(params);
      frameRef.current.contentWindow.postMessage(message, process.env.REACT_APP__RESULTS_VIEW_ORIGIN);
    } catch (e) {
      console.error(`Error occurred attempting to encode game result.\n${e}`);
    }
  }, [frameReady, details]);

  useEffect(() => {
    const onMessage = (event) => {
      if (event.origin !== process.env.REACT_APP__RESULTS_VIEW_ORIGIN) return;
      if (event.source !== frameRef.current.contentWindow) return;
      if (event.data === 'Ready') return setFrameReady(true);
      if (event.data === 'Data received') return setFrameLoaded(true);
    }

    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    }
  }, []);

  return {
    loading: loading || !frameLoaded,
    isFetching,
    frameRef,
    redirect: !loading && !details,
  };
}

export {
  useDetailsModel,
}

import React, { useCallback, useState } from 'react';
import { managerRest } from '../../../index';
import { alphaNumericRegexp, passwordRegexp } from '../../../utils/regexp/strings';
import PermissionsByOperatorBlock from './permissionsByOperatorBlock';
import { createLegacyGameOption } from '../../../utils/createOptions';
import Multiselect from '../../../uiKit/inputs/multiselect';
import { useSelector } from 'react-redux';
import { appSelectors } from '../../../redux/app/selectors';
import eUserRoles from '../../../enums/eUserRoles';

import { ReactComponent as AddIcon } from '../../../assets/icons/add-icon.svg';

export const defaultPermissions = {
  operators: [],
  games: [],
};

export const defaultOperatorPermission = {
  id: '',
  casino_list: [],
};

export const permissionFieldsByRole = {
  [eUserRoles.EUR_PARTNER]: ['operators'],
  [eUserRoles.EUR_GAME_CO_OWNER]: ['games'],
};

export const permissionDataByField = {
  operators: defaultOperatorPermission,
};

export default function useUserManagerModel(initialForm) {
  const options = useSelector(appSelectors.getOptions);
  const roles = useSelector(appSelectors.getUserRoles);
  const [users, setUsers] = useState(null);
  const [formData, setFormData] = useState(initialForm);

  const getUsers = useCallback(async () => {
    const data = await managerRest.getUsersData();
    setUsers(data);
  }, [setUsers]);

  const changeRole = (e) => {
    const roleId = parseInt(e.target.value);

    setFormData(prev => {
      const temp = { permissions: defaultPermissions };
      Object.keys(initialForm).filter(key => key !== 'permissions').forEach(key => temp[key] = prev[key]);

      permissionFieldsByRole[roleId]?.forEach(key => {
        const initialData = permissionDataByField[key];
        temp.permissions[key] = initialData ? [initialData] : [];
      });

      return {
        ...temp,
        role: roleId,
      }
    });
  };

  const onInputChange = (e) => {
    const temp = {};
    if (e.target.dataset.resetFields) {
      for (let key of e.target.dataset.resetFields.split(' ')) {
        temp[key] = '';
      }
    }

    setFormData(prev => ({
      ...prev,
      [e.target.dataset.field]: e.target.value,
      ...temp,
    }));
  };

  const changeUsername = (e) => {
    if (e.target.value !== '' && !alphaNumericRegexp.test(e.target.value)) return;
    onInputChange(e);
  };

  const changePassword = (e) => {
    if (passwordRegexp.test(e.target.value)) return;
    onInputChange(e);
  };

  const selectUser = (callback) => {
    const username = callback({}).username
    const data = users?.find(user => user.username === username) || initialForm;
    setFormData(prev => ({ ...prev, ...data }));
  }

  const changePermissionsByOperator = (index) => (e) => {
    const value = parseInt(e.target.value);

    setFormData(prev => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        operators: [...prev.permissions.operators.slice(0, index), {
          id: value,
          casino_list: [],
        }, ...prev.permissions.operators.slice(index + 1)]
      }
    }))
  }

  const changePermissionsByCasino = (index) => (value) => {
    setFormData(prev => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        operators: [...prev.permissions.operators.slice(0, index), {
          ...prev.permissions.operators[index],
          casino_list: prev.permissions.operators[index].casino_list.includes(value) ?
            prev.permissions.operators[index].casino_list.filter(casino => casino !== value) : [...prev.permissions.operators[index].casino_list, value]
        }, ...prev.permissions.operators.slice(index + 1)]
      }
    }))
  }

  const changePermissionsByGame = (value) => {
    setFormData(prev => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        games: prev.permissions.games.includes(value) ? prev.permissions.games.filter(game => game !== value) : [...prev.permissions.games, value]
      }
    }))
  }

  const addOperatorPermission = () => {
    setFormData(prev => ({
      ...prev,
      permissions: { ...prev.permissions, operators: [...prev.permissions.operators, defaultOperatorPermission] }
    }))
  }

  const removeOperatorPermission = (index) => () => {
    setFormData(prev => ({
      ...prev,
      permissions: {
        ...prev.permissions,
        operators: [...prev.permissions.operators.slice(0, index), ...prev.permissions.operators.slice(index + 1)]
      }
    }))
  }

  const getInputByKey = (key) => {
    switch (key) {
      case 'operators':
        return <div className={'w-full flex flex-col gap-2'}>
          {formData.permissions.operators.map(({ id, casino_list }, index) =>
            <PermissionsByOperatorBlock key={`${id}_${index}`} selectedOperator={id} selectedCasino={casino_list}
                                        selectOperator={changePermissionsByOperator(index)}
                                        selectCasino={changePermissionsByCasino(index)}
                                        deselectCasino={changePermissionsByCasino(index)}
                                        deleteBlock={removeOperatorPermission(index)} />
          )}
          <button type={'button'} onClick={addOperatorPermission} className={'w-max mx-auto group'}>
            <AddIcon className={'size-6 [&>path]:transition-all [&>path]:group-hover:fill-blue-200'} />
          </button>
        </div>
      case 'games':
        const gameOptions = options && options.games ? options.games.map(createLegacyGameOption) : [];
        return <Multiselect options={gameOptions} selected={formData.permissions.games} className={'[&_input]:!h-6'}
                            select={changePermissionsByGame} deselect={changePermissionsByGame}
                            placeholder={'Game'} contextClassName={'max-h-36 rounded'} />
      default:
        return <input value={formData[key]} onChange={onInputChange} data-field={key} />;
    }
  };

  return {
    roles,
    users,
    setUsers,
    formData,
    setFormData,
    getUsers,
    changeRole,
    onInputChange,
    changeUsername,
    changePassword,
    selectUser,
    getInputByKey,
  };
}

import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import Info from '../../inputForms/info';
import AddCheckboxLayout from '../../inputForms/addCheckboxLayout';
import ePartnerConfigFieldName from '../ePartnerConfigFieldName';

export default React.memo(forwardRef(function CertificationName({
                                                                  config,
                                                                  canChangeBlock,
                                                                  canOverride,
                                                                  setSaveFunctionPool,
                                                                  selectedCasino,
                                                                  overriddenBlocks,
                                                                  setOverriddenBlocks,
                                                                  getConfigOrCasinoConfigProperty
                                                                }, ref) {
  const defaultValue = 'default';
  const [certificationName, setCertificationName] = useState(defaultValue);

  const saveFunction = useCallback(() => {
    let data = {};
    if ((!selectedCasino) || (selectedCasino && overriddenBlocks[ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME])) {


      if (certificationName) {
        data[ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME] = certificationName;
      }
    }

    return data;
  }, [selectedCasino, overriddenBlocks, certificationName, defaultValue]);

  useEffect(() => {
    setSaveFunctionPool(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME]: saveFunction,
    }))
  }, [setSaveFunctionPool, saveFunction]);

  useEffect(() => {
    if (!config) return;
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME);
    setCertificationName(configData || configData === 0 ? configData : defaultValue);
  }, [config, setCertificationName, defaultValue, getConfigOrCasinoConfigProperty]);

  const changeInterval = useCallback((e) => {
    setCertificationName(e.target.value);
  }, [setCertificationName]);

  const checkOverride = () => {
    setOverriddenBlocks(prev => ({
      ...prev,
      [ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME]: !prev[ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME],
    }));

    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME);
    setCertificationName(configData || configData === 0 ? configData : defaultValue);
  };

  const reset = () => {
    const configData = getConfigOrCasinoConfigProperty(ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME);
    setCertificationName(configData || configData === 0 ? configData : defaultValue);
  };

  useImperativeHandle(ref, () => ({
    reset
  }));

  return (
    <div className={'partners_config_body_form_box'} data-disabled={!canChangeBlock}>

      <div className={'input_form'}>
        <span className={'input_form_title'}>Certification name</span>
        <select value={certificationName} onChange={changeInterval} disabled={!canChangeBlock}>
          <option value={'default'}>default</option>
          <option value={'UKGC'}>UKGC</option>
          <option value={'Malta'}>Malta</option>
          <option value={'kiron'}>kiron</option>
          <option value={'keyrunon'}>keyrunon</option>
        </select>
        {!!selectedCasino && canOverride &&
          <AddCheckboxLayout title={'override'}
                             checked={!!overriddenBlocks[ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME]}
                             checkboxId={`${ePartnerConfigFieldName.EPCFN_CERTIFICATION_NAME}_override_checkbox`}
                             setChecked={checkOverride}
                             disabled={!canOverride}
          />
        }
        <Info info={''}/>
      </div>

    </div>
  );
}));

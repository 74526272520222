import React, { useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';

export default React.memo(function BaseModal({
                                               visible,
                                               onClose,
                                               showCloseButton,
                                               showOnTop,
                                               children,
                                               onKeyDown,
                                               ...props
                                             }) {

  const onEscapeDown = useCallback((e) => {
    if (e.keyCode !== 27) return;

    onClose();
  }, [onClose]);

  useEffect(() => {
    if (visible) {
      if (showCloseButton) window.addEventListener('keydown', onEscapeDown);
      if (onKeyDown) window.addEventListener('keydown', onKeyDown);
    } else {
      window.removeEventListener('keydown', onEscapeDown);
      window.removeEventListener('keydown', onKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', onEscapeDown)
      window.removeEventListener('keydown', onKeyDown)
    };
  }, [onEscapeDown, visible, showCloseButton, onKeyDown]);

  const stopBubble = (e) => {
    e.stopPropagation();
  };

  return (
    ReactDOM.createPortal(
      <div onClick={onClose}
           className={`modal_wrapper ${visible ? 'modal_wrapper_visible' : ''} ${showOnTop ? 'modal_wrapper_error' : ''}`} {...props}>
        {!visible ? null : <div onClick={stopBubble} className={'modal_container'}>
          {showCloseButton && <span onClick={onClose} className={'modal_x_button'}>×</span>}
          {children}
        </div>}
      </div>,
      document.getElementById('root')
    )
  );
})

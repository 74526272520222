import React  from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { useRoutingPermissions } from '../../utils/useRoutingPermissions';

const PermissionRouter = React.memo(function ({ location, children }) {
  const accessGranted = useRoutingPermissions(location);
  return accessGranted ? children : <Redirect to={'/404'} />;
});

export default withRouter(PermissionRouter);
